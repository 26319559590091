import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {MemberService} from '../services/MemberService';
import {MemberAddressActionBiParams, MemberStoreProps} from '../../types/app.types';
import {NavigationService} from '../services/NavigationService';
import {CheckoutService} from '../services/CheckoutService';
import {BIService} from '../services/BIService';
import {SPECS} from '../../components/Checkout/constants';

export type MemberStoreConfig = {
  flowAPI: ControllerFlowAPI;
  memberService: MemberService;
  navigationService: NavigationService;
  checkoutService: CheckoutService;
  updateComponent: () => void;
  biService: BIService;
};

export class MemberStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly memberService: MemberService;
  private readonly navigationService: NavigationService;
  private readonly checkoutService: CheckoutService;
  private readonly biService: BIService;

  private readonly updateComponent: () => void;

  constructor({
    flowAPI,
    memberService,
    navigationService,
    checkoutService,
    updateComponent,
    biService,
  }: MemberStoreConfig) {
    this.flowAPI = flowAPI;
    this.memberService = memberService;
    this.navigationService = navigationService;
    this.checkoutService = checkoutService;
    this.biService = biService;
    this.updateComponent = updateComponent;
    if (!this.navigationService.isFastFlow) {
      void this.init();
    }
  }

  private readonly reportEditAddressClicked = (params: MemberAddressActionBiParams) => {
    this.biService.memberClickOnEditAddress(params);
  };

  private readonly reportAddAddressClicked = (params: MemberAddressActionBiParams) => {
    this.biService.memberClickOnAddAddress(params);
  };

  private async init() {
    if (this.flowAPI.experiments.enabled(SPECS.ShouldAskForMemberAreaAppInstallation)) {
      await this.memberService.setIsMemberAreaAppInstalled();
    } else {
      await this.memberService.setIsAddressesAppInstalled();
    }
    if (this.memberService.isMember()) {
      await this.memberService.getCurrentUserEmail();
    }

    if (this.memberService.isMember()) {
      await this.memberService.fetchAddressesInfo();
    }
    /* istanbul ignore next */
    this.checkoutService.checkout && this.updateComponent();
  }

  public toProps(): MemberStoreProps {
    return {
      isMember: this.memberService.isMember(),
      addressesInfo: this.memberService.addressesInfo,
      defaultAddressId: this.memberService.addressesInfo.defaultAddressId,
      isAddressesAppInstalled: this.memberService.isAddressesAppInstalled,
      isMemberAreaAppInstalled: this.memberService.isMemberAreaAppInstalled,
      currentUserEmail: this.memberService.currentUserEmail,
      reportEditAddressClicked: this.reportEditAddressClicked,
      reportAddAddressClicked: this.reportAddAddressClicked,
    };
  }
}
